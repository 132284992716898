@import "./colors.scss";
/* Graph styles */
.xyFrameAxis {
  .axis-label {
    fill: $primaryLabel;
    opacity: 0.5;
  }

  .axis-baseline {
    stroke: $textColor;
    opacity: 0.5;
  }
}

/* layout dialog style */
.sectionLabel {
  font-weight: normal;
  color: $primaryLabel;
}

.sectionSubLabel {
  font-weight: normal;
  color: $primaryLabel;
  display: flex;
  align-items: center;

  > label {
    margin-left: 10px;
  }
}

.panel {
  // background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px $boxShadow;
  // border: 1px solid $borderColor;
  border-radius: 5px;
  opacity: 1;
}

.contentItem {
  margin: 0 60px;
}

.dataPlaceholder {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ql-editor {
  min-height: 150px;
  max-height: 250px;
  overflow: scroll;
  // resize: vertical;
}

.ql-snow {
  .ql-picker {
    &.ql-size {
      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
        }
      }
    }
  }
}

.flood-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
