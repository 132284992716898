
/**/
body {
  background: #eaeaea;
}
.h1 {
  margin: 100px auto 0 auto;
  color: black;
  font-family: "Encode Sans Semi Condensed", Verdana, sans-serif;
  font-size: 10rem;
  line-height: 10rem;
  font-weight: 200;
  text-align: center;
}
.h2 {
  margin: 20px auto 30px auto;
  font-family: "Encode Sans Semi Condensed", Verdana, sans-serif;
  font-size: 1.5rem;
  font-weight: 200;
  text-align: center;
}
.h1,
.h2 {
  -webkit-transition: opacity 0.5s linear, margin-top 0.5s linear; /* Safari */
  transition: opacity 0.5s linear, margin-top 0.5s linear;
}
.loading .h1,
.loading .h2 {
  margin-top: 0px;
  opacity: 0;
}
.gears {
  position: relative;
  margin: 0 auto;
  width: auto;
  height: 200px;
}
.gear {
  position: relative;
  z-index: 0;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
  background: black;
}
.gear:before {
  position: absolute;
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  z-index: 2;
  content: "";
  border-radius: 50%;
  background: #eaeaea;
}
.gear:after {
  position: absolute;
  left: 25px;
  top: 25px;
  z-index: 3;
  content: "";
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid black;
  box-sizing: border-box;
  background: #eaeaea;
}
.gear.one {
  left: -130px;
}
.gear.two {
  top: -75px;
}
.gear.three {
  top: -235px;
  left: 130px;
}
.gear .bar {
  position: absolute;
  left: -15px;
  top: 50%;
  z-index: 0;
  width: 150px;
  height: 30px;
  margin-top: -15px;
  border-radius: 5px;
  background: black;
}
.gear .bar:before {
  position: absolute;
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  z-index: 1;
  content: "";
  border-radius: 2px;
  background: #eaeaea;
}
.gear .bar:nth-child(2) {
  transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
}
.gear .bar:nth-child(3) {
  transform: rotate(120deg);
  -webkit-transform: rotate(120deg);
}
