@import "../../styles/colors.scss";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  background-color: $offWhite;
  z-index: 100;
}

.container {
  position: fixed;
  height: 10rem;
  width: 40rem;
  z-index: 102;
  border: 1px solid $textColor;
  border-radius: 2px;
  background-color: $white;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .message {
      width: 90%;
      margin: 5px 0;
  }

  .progress {
    width: 90%;
    box-sizing: content-box;
    height: 20px;
    position: relative;
    background: $focusBorder;
    border-radius: 25px;
    padding: 2px;
    box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);

    span {
      display: block;
      height: 100%;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: rgb(62,159,209);
      background-image: linear-gradient(
        center bottom,
        rgb(62,159,209) 37%,
        rgb(84, 240, 84) 69%
      );
      box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
        inset 0 -2px 6px rgba(0, 0, 0, 0.4);
      position: relative;
      overflow: hidden;
      color: $white;
      font-weight: bold;
      text-align: center;
    }
  }
}
